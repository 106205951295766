<template>
  <div class="container">
    <h4 class="mb-5">
      {{
        addPensionForm
            ? "Add Pension Trace"
            : "Application Form | Pension Details"
      }}
    </h4>
    <Form
        :validation-schema="schema"
        class="w-100 text-left"
        @submit="handleNext"
    >
      <PensionProviderSwitch/>
      <TextInput
          label="Policy number"
          name="policy_number"
          placeholder=""
          type="text"
      />
      <TextInput label="Employer" name="employer" placeholder="" type="text"/>
      <DatePicker label="Date from" name="from_date" placeholder=""/>
      <DatePicker label="Date to" name="to_date" placeholder=""/>
      <a v-if="!addPensionForm" href="#" @click.prevent="handleBack">back</a>
      <button class="fabric-btn fabric-btn-submit">
        {{ addPensionForm == true ? "Submit" : "Next" }}
      </button>
    </Form>
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import * as Yup from "yup";

import {mapActions, mapGetters, mapMutations} from "vuex";
import dayjs from "dayjs";
import DatePicker from "@/components/form/DatePicker";
import PensionProviderSwitch from "@/components/application-manager/ProviderSwitch.vue";

export default {
  name: "PensionDetails",
  props: {
    addPensionForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pensionInput: "known"
  }),
  emits: ["next"],
  setup(props) {
    let yupShape = {};
    if (props.addPensionForm) {
      yupShape = {
        pension_provider: Yup.string().when("employer", {
          is: "",
          then: Yup.string()
              .nullable()
              .required(
                  "Either the pension provider or the employer must be entered!"
              ),
          otherwise: Yup.string().nullable()
        }),
        employer: Yup.string().nullable()
      };
    } else {
      yupShape = {
        pension_provider: Yup.string()
            .required("required")
            .nullable()
      };
    }

    const schema = Yup.object().shape(yupShape);

    return {
      schema
    };
  },
  components: {
    DatePicker,
    PensionProviderSwitch
  },
  computed: {
    ...mapGetters([
      "getWithSig",
      "getNoEmployerInput",
    ]),
    cardStyle() {
      return {
        "card-remove-border": this.addPensionForm
      };
    }
  },
  methods: {
    ...mapActions(["submitPension", "fetchProviderList"]),
    ...mapMutations(["setNotification", "setLoading"]),
    handleSwitchPensionInput(type) {
      this.pensionInput = type;
    },
    async handleBack() {
      this.$emit("back", "AboutYou");
    },
    async handleNext(params) {
      let tempParams = {};
      let provider = {};

      if (this.addPensionForm) {
        this.setLoading({bool: true});
        tempParams.employer = params.employer;
        tempParams.addPensionForm = this.addPensionForm;
        tempParams.dispatchFabricFinderQueueJob = true;
        provider.pushFinderMatch = true;
      } else {
        provider.pushFinderMatch = false;
      }

      provider.pension_provider = params.pension_provider;
      provider.policy_number = params.policy_number;
      provider.from_date = params.from_date
          ? dayjs(params.from_date).format("YYYY-MM-DD")
          : null;
      provider.to_date = params.to_date
          ? dayjs(params.to_date).format("YYYY-MM-DD")
          : null;

      tempParams.pensions = [];
      tempParams.pensions.push({
        provider
      });
      tempParams.stage = "employment-history";

      let response = await this.submitPension(tempParams).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error
        });
      });
      if (response) {
        this.fetchProviderList().catch(err => console.log(err));
        if (this.addPensionForm) {
          this.setLoading({bool: false});
          this.setNotification({
            show: true,
            type: "success",
            message: "Pension trace has been added successfully!"
          });
          this.$parent.$parent.handleClose();
        } else {
          switch (this.getNoEmployerInput) {
            case true:
              switch (this.getWithSig) {
                case true:
                  this.$emit("next", "ThankYou");
                  break;
                case false:
                  this.$emit("next", "Signature");
                  break;
              }
              break;
            case false:
              this.$emit("next", "EmploymentHistory");
              break;
          }
        }
      }
    }
  },
  beforeMount() {
    // if(this.addPensionForm === false) {
    this.fetchProviderList().catch(err => console.log(err));
    // }
  }
};
</script>
