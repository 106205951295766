<template>
  <div>
    <label
      class="pointer"
      for="knownPension1"
      @click="handleSwitchPensionInput('known')"
    >
      <input
        ref="radioChecked"
        type="radio"
        name="knownPension"
        id="knownPension1"
        checked
      />
      Known pension provider
    </label>
    &nbsp;&nbsp;&nbsp;
    <label
      class="mb-4 pointer"
      for="knownPension2"
      @click="handleSwitchPensionInput('unknown')"
    >
      <input type="radio" name="knownPension" id="knownPension2" />
      Unknown pension provider
    </label>
    <SelectInput
      v-if="pensionInput == 'known'"
      name="pension_provider"
      label="Who is the pension provider"
      :value="value"
      placeholder=""
      :options="getProviders()"
    />
    <TextInput
      v-if="pensionInput == 'unknown'"
      name="pension_provider"
      type="text"
      label="Who is the pension provider"
      placeholder=""
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ProviderSwitch",
  props: {
    value: {
      default: null
    }
  },
  data: () => ({
    pensionInput: "known"
  }),
  computed: {
    ...mapGetters(["getProviders"])
  },
  methods: {
    handleSwitchPensionInput(type) {
      this.pensionInput = type;
    }
  },
  mounted() {
    this.$refs.radioChecked.checked = true;
  }
};
</script>
