<template>
    <div class="status-update">
      <strong v-if="withTitle">Test application?</strong>
      <p v-if="!edit" class="p-0 m-0" @click="handleEdit">
        <span :class="['badge badge-pill pointer', badgeStyle]">{{
            getStatus
          }}</span>
      </p>
      <div v-else class="position-relative">
        <div
            v-if="updating"
            class="overlay-div d-flex align-items-center justify-content-center"
        >
          <p class="blink p-0 m-0">updating...</p>
        </div>
        <SelectInput
            :options="optionsComputed"
            :value="getStatusRaw"
            :with-blank-option="false"
            label=""
            name="test_application"
            @selected="updateSelect"
            @keyup.esc="handleEscape"
        />
      </div>
    </div>
  </template>
  <script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  
  export default {
    name: "TestApplication",
    props: {
      type: {
        type: String,
        default: "trace"
      },
      inactive: {
        type: Boolean,
        default: false
      },
      entity: {
        type: Object,
        default: null
      },
      withTitle: {
        type: Boolean,
        default: true
      },
    },
    emits: ["test-application-changed"],
    data: () => ({
      test: null,
      edit: false,
      updating: false
    }),
    components: {
    },
    computed: {
      ...mapGetters(["getApplication"]),
      optionsComputed() {
        return [
            {
                'id': 0,
                'name': 'No'
            },
            {
                'id': 1,
                'name': 'Yes'
            },
        ];
      },
      getStatusRaw() {
        return this.getApplication?.test;
      },
      getStatus() {
        if (this.getApplication?.test == 0) {
          return 'No'
        } else {
          return 'Yes'
        }
      },
      badgeStyle() {
        if (this.getApplication?.test == 0) {
          return 'badge-warning'
        } else {
          return 'badge-primary'
        }
      }
    },
    methods: {
      handleEscape() {
        this.edit = false;
      },
      handleEdit() {
        if (this.inactive) return;
        this.edit = true;
      },
      updateSelect(param) {
        if (this.inactive) return;
        this.updating = true;
        let params = {
          uuid: this.entity?.uuid || null,
          test: param
        };
        this.test = param;
        this.$emit("test-application-changed", params);
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .badge {
    padding: 10px;
    border-radius: 3px;
  }
  </style>
  