<template>
  <EditModal ref="editContactForm">
    <template #btn-text>

    </template>
    <template #formTitle>
      Edit Contact
    </template>
    <template #form>
      <Form
          class="w-100 text-left"
          @submit="saveForm"
          :validation-schema="schema"
      >
        <div class="row">
          <div class="col-auto">
            <TextInput
                class="region"
                name="region"
                type="text"
                label="Region"
                :value="getRegion"
            />
          </div>
          <div class="col-auto">
            <TextInput
                name="number"
                type="text"
                label="Number"
                :value="getContact"
            />
          </div>
        </div>
        <TextInput
            name="email"
            type="text"
            label="Email"
            :value="evalutateProperty('getApplication?.email')"
        />
        <button class="fabric-btn fabric-btn-submit">Save</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import * as Yup from "yup";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: "ContactForm",
  data: () => ({}),
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
          .email()
          .required(),
      number: Yup.string().required()
    });
    return {
      schema
    };
  },
  computed: {
    ...mapGetters(["getApplication", "getCurrentContact"]),
    getContact() {
      if (
          !this.getApplication?.contact[this.getApplication?.contact.length - 1]
              ?.number
      ) {
        return "No contact has been set";
      }
      return this.getApplication?.contact[
      this.getApplication?.contact.length - 1
          ]?.number;
    },
    getRegion() {
      if (
          !this.getApplication?.contact[this.getApplication?.contact.length - 1]
              ?.region
      ) {
        return "No region has been set";
      }
      return this.getApplication?.contact[
      this.getApplication?.contact.length - 1
          ]?.region;
    }
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(["updateApplication"]),
    async saveForm(params) {
      let data = {
        email: params.email,
        contact: [
          {
            id: this.getCurrentContact?.id || null,
            number: params.number,
            region: params.region
          }
        ]
      };

      const response = await this.updateApplication(data).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Contact Update Error!",
          content: err.response.data.error
        });
      });

      if (response.status == 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Contact Updated!"
        });
      }
      this.$refs.editContactForm.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.region {
  width: 70px;
}
</style>
