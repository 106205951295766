<template>
  <PopOver content="Forward&nbsp;to&nbsp;PCS">
    <icon :data="et" color="#f90" height="20" width="20" @click.prevent="handleForwardToPcs()"/>
  </PopOver>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import et from "@fa/solid/envelope.svg";

export default {
  name: "ForwardToPCSButton",
  props: {
    uuid: {
      type: String,
      default: null
    },
    emailId: {
      type: String,
      default: null
    },
  },
  emits: [],
  data: () => ({
    et
  }),
  computed: {},
  methods: {
    ...mapActions([
      "forwardToPcs"
    ]),
    ...mapMutations([
      "setNotification"
    ]),
    async handleForwardToPcs() {
      if (!confirm("Forward this email thread to PCS?")) {
        return false;
      }
      let params = {
        'uuid': this.uuid,
        'emailId': this.emailId,
      }
      let response = await this.forwardToPcs(params).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error
        });
      });

      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Sending document"
        });
      }
    }
  },
}
</script>
<style lang="scss">

</style>