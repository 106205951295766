<template>
  <div class="card h-100">
    <div class="card-body text-left h-100">
      <button class="btn btn-success mb-3" @click="handleEmail">
        Email Manager
      </button>
      <div>
        <div class="d-flex flex-row align-items-start mb-2">
          <button :class="['btn btn-info mr-1', disableStyleOne]"
                  @click="handleInboxVersion(1)">
            Legacy Inbox
          </button>
          <button :class="['btn btn-info', disableStyleTwo]"
                  @click="handleInboxVersion(2)">
            Comms Manager Inbox
          </button>
        </div>
        <div v-if="inboxVersion === 2">
          <AssignedEmailList
              :constrain-height="true"
              :email-data="emailResponse?.data?.data"
              :parent="parent"
          />
        </div>
        <div v-else-if="inboxVersion === 1">
          <AssignedLegacyEmailList
              :constrain-height="true"
              :email-data="emailLegacyResponse?.data?.data"
              :parent="parent"
          />
        </div>
      </div>
      <button
          v-if="emailList"
          class="btn btn-success mt-3"
          @click="handleEmail"
      >
        Email Manager
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {useDateFormat} from "@/logic/useDateFormat";
import AssignedEmailList from "@/components/lists/AssignedEmailList";
import AssignedLegacyEmailList from "@/components/lists/AssignedLegacyEmailList";

export default {
  name: "EmailCorrespondance",
  props: {
    parent: {
      type: String,
      default: 'tp'
    },
  },
  data: () => ({
    emailList: null,
    emailResponse: null,
    emailLegacyResponse: null,
    emailReady: false,
    inboxVersion: 2,
  }),
  components: {
    AssignedEmailList,
    AssignedLegacyEmailList,
  },
  setup() {
    const {formatDate} = useDateFormat();

    return {
      formatDate
    };
  },
  computed: {
    ...mapGetters([
      "getApplication"
    ]),
    disableStyleOne() {
      return {
        "disabled": this.inboxVersion === 2
      }
    },
    disableStyleTwo() {
      return {
        "disabled": this.inboxVersion === 1
      }
    }
  },
  methods: {
    ...mapActions([
      "getEmailTrail",
      "fetchEmailThread",
      "fetchLegacyEmailThread",
      "previewEmail"
    ]),
    handleInboxVersion(version) {
      this.inboxVersion = version;
    },
    async fetchThread() {
      this.emailResponse = await this.fetchEmailThread(
          this.$route.params.tpuuid || this.$route.params.uuid
      ).catch(err => console.log(err));

      this.emailLegacyResponse = await this.fetchLegacyEmailThread(
          this.$route.params.tpuuid || this.$route.params.uuid
      ).catch(err => console.log(err));
    },
    async handleAction(email) {
      let response = await this.previewEmail({
        activeTemplate: email.email_template,
        tpuuid: this.$route.params.tpuuid,
        data: JSON.parse(email.contents) || {}
      });
      if (response.status == 200) {
        this.emailReady = true;
        this.$refs.emailIframe.srcdoc = response.data.data.body;
      }
    },
    handleEmail() {
      this.$router.push({
        name: "Email Send Interface",
        params: {
          tpuuid: this.$route.params.tpuuid || this.$route.params.uuid,
        },
        query: {
          parent: this.parent,
          replyEmail: this.getApplication?.email
        }
      });
    }
  },
  async beforeMount() {
    await this.fetchThread();
  }
};
</script>
<style lang="scss" scoped>
#emailIframe {
  width: 600px;
  height: 80vh;
  background: rgb(255, 255, 255);
  outline-color: #d8d8d8;
  outline-width: 5px;
  outline-style: solid;
}
</style>
