import dayjs from "dayjs";

export function useDateFormat() {
  const formatDate = date => {
    let d = dayjs(date).format("DD/MM/YYYY");

    if (d == "Invalid Date") {
      return "No date set";
    }

    return d;
  };

  return {
    formatDate
  };
}
