<template>
  <div>
    <div class="row">
      <div class="col-12 text-right mb-4">
        <button class="btn btn-warning" @click="handleUpload">Upload</button>
      </div>
    </div>
    <div
      class="mb-2"
      v-for="(item, index) in attachments"
      :key="index"
      @click="handleOpenAttachment(item.link)"
    >
      <icon
        v-if="item.ext == 'png' || item.ext == 'jpg' || item.ext == 'jpeg'"
        :data="image"
        width="20"
        height="20"
        color="blue"
        dir="up"
      />
      <icon
        v-if="item.ext == 'pdf'"
        :data="pdf"
        width="20"
        height="20"
        color="red"
        dir="up"
      />
      <icon
        v-if="item.ext == 'docx'"
        :data="word"
        width="20"
        height="20"
        color="green"
        dir="up"
      />
      {{ getFilename(item.link) }}&nbsp;&nbsp;
    </div>
    <hr />
  </div>
</template>
<script>
import pdf from "@fa/regular/file-pdf.svg";
import image from "@fa/regular/file-image.svg";
import word from "@fa/regular/file-word.svg";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EmailAttachment",
  props: {
    attachments: {
      default: null
    }
  },
  data: () => ({
    pdf,
    word,
    image
  }),
  computed: {
    ...mapGetters(["getApplicationUuid", "getTracedPensionUuid"])
  },
  methods: {
    ...mapMutations(["setAttachments"]),
    handleUpload() {
      this.setAttachments(this.attachments);
      this.$router.push({
        name: "File Upload",
        query: {
          auuid: this.getApplicationUuid,
          tpuuid: this.getTracedPensionUuid,
          copy: true
        }
      });
    },
    handleOpenAttachment(link) {
      window.open(link, "_blank").focus();
    },
    getFilename(url) {
      let resultArray1 = url.split("/");
      let resultArray2 = resultArray1[resultArray1.length - 1].split("?");
      return resultArray2[0];
    }
  }
};
</script>
<style lang="scss" scoped></style>
