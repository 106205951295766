<template>
<!--  <div class="font-weight-bold">Status history:</div>-->
  <strong>Previous status:</strong> {{statePrevious?.status}} <br>
  <strong>Date changed:</strong> {{ formatReadDateTime(statePrevious?.entered_at) }} <br>
  <strong>Updated by:</strong> {{getCausedBy}}
  <InfoModal ref="previousName">
    <template #btn-text>
      <span class="link-text">view history</span>
    </template>
    <template #title>
      Status change history
    </template>
    <template #content>
      <table class="table table-hover table-striped text-left">
        <thead class="thead-light">
        <tr>
          <th scope="col" class="font-weight-bold">Status</th>
          <th scope="col" class="font-weight-bold">Date</th>
          <th scope="col" class="font-weight-bold">Manual update</th>
          <th scope="col" class="font-weight-bold">Updated by</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(t, i) in stateList"
            :key="i"
            :class="{'table-active': i === 0}"
        >
          <td class="align-middle">
            <span class="badge">
              {{capitalizeFirstLetter(t.status).replace(/-/g, " ")}}
            </span>
            <span v-if="
              objectType === 'trace' &&
              t.status === 'complete' &&
              typeof t.closed_reason == 'string'
            " class="ml-2 text-muted"> - {{t.closed_reason}}</span>
          </td>
          <td class="align-middle">
            {{ formatReadDateTime(t.entered_at) }}
          </td>
          <td class="align-middle">
            <span :class="{'text-success': t.manual_flag === 1, 'text-secondary': t.manual_flag !== 1}">
              {{ t.manual_flag === 1 ? "Yes" : "No"}}
            </span>
          </td>
          <td class="align-middle">
            {{ t.manual_flag === 1 ? t.caused_by?.first_name + ' ' + t.caused_by?.last_name || "Automation" : "Automation" }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </InfoModal>
</template>

<script>
export default {
  props: {
    objectType: {
      type: String,
      default: 'trace',
      required: true,
    },
    stateCurrent: {
      type: Object,
      required: true,
    },
    statePrevious: {
      type: Object,
      required: true,
    },
    stateList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getCausedBy() {
      let name = null;
      if(this.statePrevious?.caused_by?.first_name) {
        name = this.statePrevious?.caused_by?.first_name + ' ' + this.statePrevious?.caused_by?.last_name
      }
      return name || 'Automation';
    },
  },
  methods: {

  },
}
</script>

<style lang="scss">

</style>