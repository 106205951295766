<template>
  <EditModal ref="editContactForm">
    <template #btn-text>
      edit application details
    </template>
    <template #formTitle>
      Edit Application
    </template>
    <template #form>
      <Form
        class="w-100 text-left"
        @submit="saveForm"
        :validation-schema="schema"
      >
        <TextInput
          name="title"
          type="text"
          label="Title"
          :value="evalutateProperty('getApplication?.title')"
          class="pr-1"
        />
        <TextInput
          name="first_name"
          type="text"
          label="First Name"
          :value="evalutateProperty('getApplication?.first_name')"
          class="pr-1"
        />
        <TextInput
          name="last_name"
          type="text"
          label="Last Name"
          :value="evalutateProperty('getApplication?.last_name')"
        />
        <TextInput
          name="maiden_name"
          type="text"
          label="Maiden Name"
          :value="evalutateProperty('getApplication?.maiden_name')"
        />
        <DatePicker
          name="dob"
          label="Date of birth"
          placeholder=""
          :value="dobDate"
        />
        <TextInput
          name="ni_number"
          type="text"
          label="National Insurance Number"
          :value="evalutateProperty('getApplication?.ni_number')"
        />
        <button class="fabric-btn fabric-btn-submit">Next</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import * as Yup from "yup";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useDateFormat } from "@/logic/useDateFormat";
import DatePicker from "@/components/form/DatePicker";

export default {
  name: "ApplicationForm",
  components: {
    DatePicker
  },
  data: () => ({}),
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email()
        .required(),
      phone: Yup.string().required()
    });
    const { formatDate } = useDateFormat();
    return {
      schema,
      formatDate
    };
  },
  computed: {
    ...mapGetters(["getApplication"]),
    getContact() {
      if (!this.getApplication?.contact[0]?.number) {
        return "No contact has been set";
      }
      return this.getApplication?.contact[0]?.number;
    },
    dobDate() {
      let tempdate = this.parseDate(
        this.evalutateProperty("getApplication?.dob")
      );
      if (tempdate) {
        return tempdate;
      }
      return null;
    }
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(["updateApplication"]),
    async saveForm(params) {
      let data = {
        ...params
      };

      const response = await this.updateApplication(data).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Application Update Error!",
          content: err.response.data.error
        });
      });

      if (response.status == 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Application Updated!"
        });
      }
      this.$refs.editContactForm.show = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
