<template>
  <div>
    <ScrollerWrap
        id="ScrollerWrap_3"
        :constrain-height="constrainHeight"
        :next="computedData?.next_page_url"
        class="scroller-wrap -height-80 -bottom-padding-150"
        @update-list="handleLazyUpdate"
    >
      <div>
        <table class="table table-hover text-left">
          <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">&nbsp;</th>
            <th scope="col">Email</th>
            <th scope="col">Subject</th>
            <th scope="col">Sent/Received</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in computedData?.data || computedData"
              :key="index"
              :class="['table-row', unreadStyle(item.read)]"
          >
            <td scope="row">
              <icon
                  v-if="item.flag == false"
                  :data="starOutline"
                  color="orange"
                  height="18"
                  width="18"
                  @click="handleFlag(item, 1)"
              />
              <icon
                  v-else
                  :data="starSolid"
                  color="orange"
                  height="18"
                  width="18"
                  @click="handleFlag(item, 0)"
              />
            </td>
            <td>
              <div v-if="item.type == 'inbound'">
                <ForwardToPCSButton
                    v-if="parent === 'app' || parent === 'tp'"
                    :emailId="item.id"
                    :uuid="$route.params.tpuuid || $route.params.uuid"
                />&nbsp;&nbsp;
              </div>
            </td>
            <td scope="row">
              <div class="d-flex">
                  <span v-if="item.type == 'inbound'"
                  ><icon :data="received" color="grey" height="18" width="18"
                  /></span>
                <span v-else
                ><icon :data="sent" color="grey" height="18" width="18"
                /></span>
                <span>&nbsp;{{ item.from_email || "FF" }}</span>
              </div>
            </td>
            <td class="d-flex justify-content-start align-items-center" scope="row">
              <InfoModal
                  ref="emailPreview"
                  :class="sentStyle(item.type)"
                  @doaction="handleAction(item)"
              >
                <template #btn-text>
                    <span v-if="hasAttachment(item)">
                      <span
                          v-if="item?.read === 0">
                        <icon
                            :data="unread"
                            color="orange"
                            height="18"
                            width="18"
                        />
                        &nbsp;
                      </span>
<!--                      Place pill here to signify if sent manually or by automation -->
                      <span>{{ item.subject || item.title }}</span>
                      &nbsp;
                      <icon
                          :data="paperclip"
                          color="orange"
                          height="18"
                          width="18"
                      />
                    </span>
                    <span v-else>
                      <span v-if="typeof JSON.parse(item.contents).isManual === 'boolean'" :class="['badge mr-2', {
                        'badge-warning': JSON.parse(item.contents).isManual === false,
                        'badge-info': JSON.parse(item.contents).isManual === true,
                      }]">{{ JSON.parse(item.contents).isManual === true ? "Manual" : "Automation" }}</span>
                      <span>{{ item.subject || item.title }}</span>
                    </span>
                </template>
                <template #content>
                  <EmailViewer
                      ref="emailViewer"
                      :index="index"
                      :item="item"
                      :parent="parent"
                  />
                </template>
              </InfoModal>
            </td>
            <td scope="row">
              <span class="d-inline-flex">
                <PopOver v-if="!item.type" content="Email&nbsp;not&nbsp;sent!">
                  <icon
                      v-if="item.send_status == 0"
                      :data="et"
                      color="red"
                      dir="up"
                      height="20"
                      width="20"
                  />&nbsp;
                </PopOver>
                {{ formatReadDateTime(item.created_at) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ScrollerWrap>
  </div>
</template>
<script>
import starOutline from "@fa/regular/star.svg";
import starSolid from "@fa/solid/star.svg";
import received from "@fa/solid/share.svg";
import paperclip from "@fa/solid/paperclip.svg";
import sent from "@fa/solid/reply.svg";
import unread from "@fa/regular/paper-plane.svg";
import et from "@fa/solid/exclamation-triangle.svg";
import {mapActions, mapGetters} from "vuex";
import EmailViewer from "@/components/email-manager/EmailViewer";
import ForwardToPCSButton from "@/components/email-manager/ForwardToPCSButton";

export default {
  name: "AssignedEmailList",
  props: {
    parent: {
      type: String,
      default: 'tp'
    },
    constrainHeight: {
      type: Boolean,
      default: false
    },
    emailData: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    starOutline,
    starSolid,
    received,
    paperclip,
    sent,
    unread,
    et,
    lazyData: null,
    emailIframeContent: null
  }),
  components: {
    EmailViewer,
    ForwardToPCSButton,
  },
  computed: {
    computedData() {
      return this.lazyData || this.emailData;
    }
  },
  methods: {
    ...mapActions(["markRead", "markFlag", "previewEmail"]),
    handleLazyUpdate(data) {
      if (typeof data === 'undefined') return;
      this.lazyData = this.emailData
      this.lazyData.next_page_url = data.next_page_url
      this.lazyData.data = this.lazyData.data.concat(data.data)
    },
    hasAttachment(item) {
      if (item.attachments == null) {
        return false;
      }
      if (item.attachments?.length == 0) {
        return false;
      }
      return true;
    },
    handleAttachment() {
      console.log("open attachment");
    },
    async handleFlag(email, flag) {
      let id = email.id;
      let content = email.content || email.contents;
      await this.markFlag({id, content, flag}).catch(err => console.log(err));
      this.$parent.fetchThread();
    },
    async loadEmailIframe() {
      let response = await this.previewEmail({
        activeTemplate: this.emailIframeContent.email_template,
        tpuuid: this.$route.params.uuid,
        data: JSON.parse(this.emailIframeContent.contents) || {}
      });
      if (response.status == 200) {
        this.$nextTick(() => {
          this.$refs.emailViewer.emailReady = true;
          this.$refs.emailViewer.$refs.emailIframe.srcdoc =
              response.data.data.body;
        });
      }
    },
    async handleAction(email) {
      if (!email.type) {
        this.$nextTick(() => {
          this.$refs.emailViewer.showHtml = true;
        });
        this.emailIframeContent = email;
        this.loadEmailIframe();
      } else {
        let id = email.id;
        this.emailIframeContent = null;
        await this.markRead({id}).catch(err => console.log(err));
        this.$parent.fetchThread();
      }
    },
    unreadStyle(read) {
      if (typeof read == "undefined") {
        read = true;
      }
      return {
        unread: !read
      };
    },
    sentStyle(type) {
      if (typeof type == "undefined") {
        type = "outbound";
      }
      return {
        outboundIframe: type == "outbound"
      };
    }
  },
};
</script>
<style lang="scss" scoped>
.outboundIframe {
  .edit-modal {
    iframe {
      width: 600px;
      height: 600px;
    }
  }
}

.unread {
  span {
    font-weight: bold !important;
  }
}

.pointer-events-all {
  pointer-events: all;
}
</style>
