<template>
  <EditModal ref="editAddressForm">
    <template #btn-text>
      
    </template>
    <template #formTitle>
      Edit Address
    </template>
    <template #form>
      <Form
        class="w-100 text-left"
        @submit="saveForm"
        :validation-schema="schema"
      >
        <TextInput
          name="address_1"
          type="text"
          label="Address Line 1"
          :value="evalutateProperty('getCurrentAddress?.address_1')"
        />
        <TextInput
          name="address_2"
          type="text"
          label="Address Line 2"
          :value="evalutateProperty('getCurrentAddress?.address_2')"
        />
        <TextInput
          name="address_3"
          type="text"
          label="Address Line 3"
          :value="evalutateProperty('getCurrentAddress?.address_3')"
        />
        <TextInput
          name="town"
          type="text"
          label="Town/City"
          :value="evalutateProperty('getCurrentAddress?.town')"
        />
        <TextInput
          name="county"
          type="text"
          label="County"
          :value="evalutateProperty('getCurrentAddress?.county')"
        />
        <TextInput
          name="country"
          type="text"
          label="Country"
          :value="evalutateProperty('getCurrentAddress?.country')"
        />
        <TextInput
          name="postcode"
          type="text"
          label="Post Code"
          :value="evalutateProperty('getCurrentAddress?.postcode')"
        />
        <button class="fabric-btn fabric-btn-submit">Save</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import * as Yup from "yup";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AddressForm",
  data: () => ({}),
  setup() {
    const schema = Yup.object().shape({
      address_1: Yup.string().required(),
      town: Yup.string().required(),
      postcode: Yup.string()
        .required()
        .matches(
          /[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]?\s?[0-9]{1,2}[A-Za-z]{2}|([AC-FHKNPRTV-Y]\d{2}|D6W)\s[0-9AC-FHKNPRTV-Y]{4}/,
          {
            message: "Must be a valid postcode",
            excludeEmptyString: true
          }
        )
    });
    return {
      schema
    };
  },
  computed: {
    ...mapGetters(["getApplication", "getCurrentAddress"])
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(["updateApplication"]),
    async saveForm(params) {
      let data = {
        address: [
          {
            id: this.getCurrentAddress?.id || null,
            address_1: params.address_1,
            address_2: params.address_2,
            address_3: params.address_3,
            postcode: params.postcode,
            town: params.town,
            county: params.county,
            country: params.country
          }
        ]
      };

      const response = await this.updateApplication(data).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Address Error!",
          content: err.response.data.error
        });
      });

      if (response.status == 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Address Updated!"
        });
      }
      this.$refs.editAddressForm.show = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
