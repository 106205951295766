<template>
  <div>
    <InfoModal ref="previousName" v-if="src">
      <template #btn-text>
        View Signature
        <icon class="bg-transparent text-secondary pl-1" :data="signature" width="26" height="26" dir="up"/>
        
      </template>
      <template #title>
        Signature
      </template>
      <template #content>
        <div class="signature-view text-center">
          <img :src="src" />

          <p v-if="message" class="mt-3">{{ message }}</p>
        </div>
      </template>
    </InfoModal>
      <span v-if="!src" class="font-weight-bold pr-1">Missing Signature!</span>
      <span v-if="!src">
           <icon class="bg-transparent text-danger" :data="et" width="18" height="18" dir="up"/>
      </span>
    
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import et from "@fa/solid/exclamation-triangle.svg";
import signature from "@fa/solid/signature.svg";
export default {
  name: "Siganture",
  data: () => ({
    src: null,
    message: null,
    et,
    signature
  }),
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  methods: {
    ...mapActions(["getSignature"])
  },
  async beforeMount() {
    let uuid = this.$route.params.uuid;
    let response = await this.getSignature({ uuid }).catch(err =>
      console.log(err)
    );
    if (response.data.status == "success") {
      this.src = `${response.data.link}`;
    } else if (response.data.status == "error") {
      this.message = response.data.message;
    }
  }
};
</script>
<style lang="scss" scoped>
.signature-view {
  img {
    width: 100%;
  }
}
</style>
